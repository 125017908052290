body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

/* Extra small devices (portrait phones, less than 576px) */
/* No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
}
