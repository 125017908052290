.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
}

.space-before-naat-body {
    margin-top: 200px;
}

@media (min-width: 576px) {
    .space-before-naat-body {
        margin-top: 300px;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
    .space-before-naat-body {
        margin-top: 300px;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
    .space-before-naat-body {
        margin-top: 300px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
    .space-before-naat-body {
        margin-top: 500px;
    }
}
