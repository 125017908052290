.dropdown-menu {
  max-height: 500px;
  overflow-y: scroll;
}

.naat_khawan_desc {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.nav_link_style {
  color: red;
  /* font-size: 50px; */
}

.font-modal {
  width: 50vw /* Occupy the 50% of the screen width */;
  height: 50vw;
}

@font-face {
  font-family: 'algreya_bold';
  src: local('algreya_bold'), url(./fonts/algreya_bold.otf) format('truetype');
}

@font-face {
  font-family: 'algreya_regular';
  src: local('algreya_regular'),
    url(./fonts/algreya_regular.otf) format('truetype');
}

@font-face {
  font-family: 'bitter_regular';
  src: local('bitter_regular'),
    url(./fonts/bitter_regular.ttf) format('truetype');
}

@font-face {
  font-family: 'bree_serif';
  src: local('bree_serif'), url(./fonts/bree_serif.ttf) format('truetype');
}

@font-face {
  font-family: 'font_c';
  src: local('font_c'), url(./fonts/font_c.otf) format('truetype');
}

@font-face {
  font-family: 'itim';
  src: local('itim'), url(./fonts/itim.ttf) format('truetype');
}

@font-face {
  font-family: 'patrick_hand';
  src: local('patrick_hand'), url(./fonts/patrick_hand.ttf) format('truetype');
}

@font-face {
  font-family: 'roboto_regular';
  src: local('roboto_regular'),
    url(./fonts/roboto_regular.ttf) format('truetype');
}

@font-face {
  font-family: 'ubuntu_regular';
  src: local('ubuntu_regular'),
    url(./fonts/ubuntu_regular.ttf) format('truetype');
}

/*  Background Image */

/* hr.type_1 {
    border: 0;
    height: 55px;
    background-image: url(/naatwebsites/seperator.webp);
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 50px;
} */

/* .space-left-naat-body {
    background-image: url(./fonts/paper.png);
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
} */

@media (min-width: 576px) {
  /* .space-left-naat-body {
        margin-left: 50px;
    } */
}

/* Medium devices (tablets, 768px and up) */

@media (min-width: 768px) {
  .space-left-naat-body {
    margin-left: 30px;
  }
}

/* Large devices (desktops, 992px and up) */

@media (min-width: 992px) {
  .space-left-naat-body {
    margin-left: 75px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */

@media (min-width: 1200px) {
  .space-left-naat-body {
    margin-left: 150px;
  }
}
