.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.app_background {
    height: 100%;
    /* background: linear-gradient(rgba(186, 255, 204, 0.45),rgba(186, 255, 204, 0.45)); */
}

.app_background2 {
    height: 100%;
    /* background: linear-gradient(rgba(186, 255, 204, 0.45),rgba(186, 255, 204, 0.45)); */
    margin-top: 300px;
}

.app_background3 {
    height: 100%;
    /* background: linear-gradient(rgba(186, 255, 204, 0.45),rgba(186, 255, 204, 0.45)); */
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.scroll-to-top {
    position: fixed;
    bottom: 5%;
    right: 12%;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    background: #1229b9;
    color: #fff;
    z-index: 1;
    margin-bottom: 25px;
}

.scroll-to-top .icon {
    margin-top: 16px;
    animation: scrollTop 0.5s alternate ease infinite;
}

@keyframes scrollTop {
    from {
        transform: translateY(2px);
    }
    to {
        transform: translateY(-1px);
    }
}
